import React from "react"
import Img from "gatsby-image"


export default function LargeHeader(props) {
  const { className, image, flip, regSpace, style, alt, ...rest } = props

  return (
    <section className={className}>
      <div {...rest} className={`ct largeSection ${flip ? "largeSection--flip" : ""} ${regSpace ? "largeSection--regSpace" : ""}`} style={style}>
        {image ? (
          <div className="largeSection__image">
            <Img
              fluid={image.childImageSharp.fluid}
              alt={alt}
              style={{
                width: "100%",
              }}
            />
          </div>
        ) : null}
    
        <div className="largeSection__content">
          {props.children}
        </div>
      </div>
    </section>
   )
}
