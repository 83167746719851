import React from "react"
import { graphql, Link } from "gatsby"

import LargeHeader from "../components/LargeHeader"
import SEO from "../components/seo"

export default function Home({ data }) {
  const { image1 } = data

  return (
    <>
      <SEO
        title="Sorry... the redirect failed."
        description="Let's get in touch."
        article={false}
        noIndex={true}
      />
      <LargeHeader image={image1} style={{ marginBottom: "80px" }}>
        <h1 className="giant">
          Shoot!{" "}
          <span role="img" aria-label="embarrassed emoji">
            😳
          </span>
        </h1>
        <h3 className="primary">The redirect failed...</h3>
        <p>Sorry, but I could not find the link you are looking for.</p>
        <div>
          <Link className="button" to="/">
            Go to Home page
          </Link>
        </div>
      </LargeHeader>
    </>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "404x.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1250) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
